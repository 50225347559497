
/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins Regular';
  font-style: normal;
  font-weight: 400;
  src: local('Poppins Regular'), local('Poppins-Regular'),
       url('./fonts/poppins-v9-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/poppins-v9-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* poppins-500 - latin */
  @font-face {
  font-family: 'Poppins Medium';
  font-style: normal;
  font-weight: 500;
  src: local('Poppins Medium'), local('Poppins-Medium'),
       url('./fonts/poppins-v9-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/poppins-v9-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* poppins-700 - latin */
  @font-face {
  font-family: 'Poppins Bold';
  font-style: normal;
  font-weight: 700;
  src: local('Poppins Bold'), local('Poppins-Bold'),
       url('./fonts/poppins-v9-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/poppins-v9-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* poppins-800 - latin */
  @font-face {
  font-family: 'Poppins ExtraBold';
  font-style: normal;
  font-weight: 800;
  src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'),
       url('./fonts/poppins-v9-latin-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/poppins-v9-latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

body {
  margin: 0;
  font-family: 'Poppins Regular', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1E1E1E;
  background: url('./bg.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
.defaultHeadline {
  font-family: 'Poppins Bold', 'Helvetica Neue', sans-serif !important;
  color: #fff !important;
  font-size: 2em !important;
}

p,
.defaultTypo {
  font-family: "Poppins Medium",Arial,sans-serif !important;
  font-size: 15px !important;
  line-height: 26px !important;
  margin-bottom: 30px !important;
  color: rgba(255,255,255,0.62) !important;
  max-width: 680px !important;
}

.contact {
  text-align: center;
  display: inline-block;
  width: 100%;
  margin: 15px auto;
  font-family: "Poppins Medium",Arial,sans-serif !important;
  font-size: 15px !important;
  line-height: 26px !important;
  margin-bottom: 30px !important;
  color: rgba(255,255,255,0.62) !important
}

.aligned {
  text-align: center;
}

.MuiFormControl-root {
  width: 100%;
  margin-bottom: 40px !important;
}

input {
  color: rgba(255,255,255,0.62) !important;
  padding-left: 0px !important;
  font-size: 15px !important;
  width: 100%;
}

fieldset {
  border: 0 !important;
  border-radius: 0 !important;
  border-bottom: 1px solid rgba(255,255,255,0.62) !important;
  display: block;
  color: rgba(255,255,255,0.62) !important;
}

button {
  background: #9200ff !important;
  padding: 10px 25px !important;
  font-size: 16px !important;
  font-family: 'Poppins Bold', 'Helvetica Neue', sans-serif !important;
}

button:hover {
  background: #C5770C !important;
}

.mainLogo {
  text-align: center;
  margin: 0 auto -25px;
  display: block;
  max-width: 200px;
}

.inner {
  background: #1E1E1E;
  padding: 25px;
}